<template functionnal>
  <section class="section">
    <div class="template pb-4">
      <div class="template__content mb-2">
        <h1>Politique de confidentialité du site Femmes Initiatives</h1>
        <br />

        <div>
          <h6>Traitement des données à caractère personnel</h6>
          <p>
            Le site www.femmesinitiatives.org est est à l’initiative de l’Association Femmes
            Initiatives, représentée par Madame Félicité BILOA. <br />
            <b>Pour quoi nous utilisons vos données ?</b> <br />
            Le site www.femmesinitiatives.org vise à présenter les différentes activités proposées
            par l’Association Femmes Initiatives et les personnes qui participent à la mise en œuvre
            de ces activités.
          </p>
        </div>

        <div>
          <h6>Quelles données sont utilisées par Femmes Initiatives ?</h6>
          <p>
            Le site peut traiter les données à caractère personnel suivantes : <br />
            - Données d’hébergeur/de connexion <br />
            - Cookies
          </p>
        </div>

        <div>
          <h6>Sommes-nous autorisés à les utiliser ?</h6>
          <p>
            Les données traitées par la plateforme ont plusieurs fondements juridiques : <br />
            - Le consentement de la personne concernée pour une ou plusieurs finalités spécifiques
            au sens de l’article 6-a du RGPD et en application de l’article 5(3) de la directive
            2002/58/CE modifiée <br />
            - L’obligation légale à laquelle est soumise le responsable de traitements au sens de
            l’article 6-c du RGPD. <br /><br />
            <b>Ces fondements sont précisés ci-dessous :</b><br /><br />

            <b>a) Données d’hébergeur ou de connexion</b><br />
            Ce traitement est nécessaire au respect d'une obligation légale à laquelle le
            responsable de traitement est soumis au sens de l'article 6-c du Règlement (UE) 2016/679
            du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des
            personnes physiques à l'égard du traitement des données à caractère personnel et à la
            libre circulation de ces données. <br />
            L'obligation légale est posée par la loi LCEN n° 2004-575 du 21 juin 2004 pour la
            confiance dans l'économie numérique et par les articles 1 et 3 du décret n°2011-219 du
            25 février 2011.

            <br /><br />

            <b>b) Cookies</b><br />
            En application de l’article 5(3) de la directive 2002/58/CE modifiée concernant le
            traitement des données à caractère personnel et la protection de la vie privée dans le
            secteur des communications électroniques, transposée à l’article 82 de la loi n°78-17 du
            6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, les traceurs ou
            cookies suivent deux régimes distincts. <br />
            Les cookies strictement nécessaires au service, ceux de publicité non personnalisée ou
            n’ayant pas pour finalité exclusive de faciliter la communication par voie électronique
            sont dispensés de consentement préalable au titre de l’article 82 de la loi n°78-17 du 6
            janvier 1978. <br />
            Les autres cookies n’étant pas strictement nécessaires au service ou n’ayant pas pour
            finalité exclusive de faciliter la communication par voie électronique doivent être
            consenti par l’utilisateur. <br />
            Ce consentement de la personne concernée pour une ou plusieurs finalités spécifiques
            constitue une base légale au sens du RGPD et doit être entendu au sens de l'article 6-a
            du Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif
            à la protection des personnes physiques à l'égard du traitement des données à caractère
            personnel et à la libre circulation de ces données.
          </p>
        </div>

        <div>
          <h6>Combien de temps conservons-nous vos données ?</h6>
          <p>
            <b>Données d’hébergeur:</b> 1 an, conformément au décret n°2011-219 du 25 février 2011.
            <br />
            <b>Cookies:</b> Dès le retrait du consentement ou dans un délai de 13 mois, conformément
            aux recommandations de la CNIL<br />
          </p>
        </div>

        <div>
          <h6>Vos droits</h6>
          <p>
            Vous disposez des droits suivants concernant vos données à caractère personnel : <br />
            - Droit d’information et droit d’accès aux données <br />
            - Droit au retrait du consentement (en matière de cookies seulement). <br />
            Pour les exercer, faites-nous parvenir une demande en précisant la date et l’heure
            précise de la requête – ces éléments sont indispensables pour nous permettre de
            retrouver votre recherche – par voie électronique à l’adresse suivante :
            femmesinitiatives@free.fr <br />
            En raison de l’obligation de sécurité et de confidentialité dans le traitement des
            données à caractère personnel qui incombe au responsable de traitement, votre demande ne
            sera traitée que si vous apportez la preuve de votre identité. <br />Pour vous aider
            dans votre démarche, vous trouverez ici
            <a
              href="https://www.cnil.fr/fr/modele/courrier/exercer-son-droit-dacces"
              target="_blank"
              >https://www.cnil.fr/fr/modele/courrier/exercer-son-droit-dacces</a
            >, un modèle de courrier élaboré par la CNIL. <br />
            Le responsable de traitement s’engage à répondre dans un délai raisonnable qui ne
            saurait dépasser 1 mois à compter de la réception de votre demande.b
          </p>
        </div>

        <div>
          <h6>Qui peut avoir accès aux données ?</h6>
          <p>
            Le responsable de traitement s’engage à ce que les données à caractères personnels
            soient traitées par les seules personnes autorisées.
          </p>
        </div>

        <div>
          <h6>Sécurité et confidentialité des données</h6>
          <p>
            Le responsable de traitements veille à la protection par des mesures techniques et
            organisationnelles pour assurer la confidentialité, l’intégrité et protéger l’accès des
            données.
          </p>
        </div>

        <div>
          <h6>Sous-traitants</h6>
          <p>
            Certaines des données sont envoyées à des sous-traitants pour réaliser certaines
            missions. Le responsable de traitement s'est assuré de la mise en œuvre par ses
            sous-traitants de garanties adéquates et du respect de conditions strictes de
            confidentialité, d’usage et de protection des données.

            <table class="table bordered">
              <tr>
                <th>Partenaire</th>
                <th>Pays destinataire</th>
                <th>Traitement réalisé</th>
                <th>Garanties</th>
              </tr>
              <tr>
                <td>Amazon Web Services</td>
                <td>Etats-Unis</td>
                <td>Hébergement</td>
                <td>https://www.ovh.com/fr/protection-donnees-personnelles/ </td>
              </tr>
              <tr>
                <td>Google</td>
                <td>Etats-Unis</td>
                <td>Conservation de contenu du site</td>
                <td>https://policies.google.com/technologies/cookies?hl=fr </td>
              </tr>
            </table>
          </p>
        </div>

        <div>
          <h6>Cookies</h6>
          <p>
            Un cookie est un fichier déposé sur votre terminal lors de la visite d’un site. Il a pour but de collecter des informations relatives à votre navigation et de vous adresser des services adaptés à votre terminal (ordinateur, mobile ou tablette).
            <br>
            Les traceurs ont vocation à être conservés pour une durée allant jusqu'à 13 mois.
            <br>
          Certains cookies permettent d’établir des mesures statistiques de fréquentation et d’utilisation du site pouvant être utilisées à des fins de suivi et d’amélioration du service

            <table class="table bordered">
              <tr>
                <th>Cookies</th>
                <th>Traitement réalisé</th>
                <th>Base juridique</th>
                <th>Garanties</th>
              </tr>
              <tr>
                <td>GStatic</td>
                <td>Service Google aidant à conserver le contenu statique</td>
                <td>Consentement</td>
                <td>https://policies.google.com/technologies/cookies?hl=fr</td>
              </tr>
              <tr>
                <td>GoogleFontsAPI</td>
                <td>Service Google visant à livrer intelligemment les fichiers dans un format optimal</td>
                <td>Consentement</td>
                <td>https://policies.google.com/technologies/cookies?hl=fr </td>
              </tr>
              <tr>
                <td>Google.com</td>
                <td>Service Google à visée publicitaire</td>
                <td>Consentement</td>
                <td>https://policies.google.com/technologies/cookies?hl=fr </td>
              </tr>
            </table>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.template .template__content {
  min-height: 90vh;
}
</style>
